@import "../_variables";
@import "header/header_mobile.scss";
@import "header/header_desktop.scss";
@import "header/header_hamburger.scss";
@import "header/header_scrolled.scss";

body {
	padding-top: $nav-height;
  @media (max-width: $nav-bp - 1) {
    padding-top: 97px;
  }
}
