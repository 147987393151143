@import "../variables";

// Search incon in header


.search-button {
  display: block;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

// Search modal
.ss-result {
	padding: 10px;
	margin-bottom: 20px;
	h4 {
		font-size: 24px;
		display: flex;
    align-items: center;
		margin-bottom: 14px;
		//text-transform: uppercase;
		position: relative;
		font-weight: 700;
		a {
			color: #333;
		}
	}
	mark {
		font-weight: 600;
		display: inline-block;
		padding: 2px 6px;
		border-radius: 6px;
	}
	p {
		margin-bottom: 8px;
	}
	.ss-result__badge {
		display: inline-block;
		font-size: 11px;
		padding: 6px 11px;
		background-color: $base-color;
		color: #fff;
		margin-left: 10px;
		border-radius: 8px;
		vertical-align: top;
		&.badge-red {
			background-color: $base-color;
		}
		&.badge-blue {
			background-color: $green;
		}
	}
	.ss-result__link {
		font-weight: 600;
		position: relative;
		color: #333;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 8px;
        &:hover {
            .ss-result__link-img {
                animation: arrow-right 500ms;
            }
        }
	}
}

@keyframes arrow-right {
	0% {
		opacity: 1;
		transform: translateX(0) translateY(0) translateZ(0);
	}

	53% {
		opacity: 0;
		transform: translateX(15px) translateY(0) translateZ(0);
	}

	76% {
		opacity: 0;
		transform: translateX(-15px) translateY(0) translateZ(0);
	}

	100% {
		opacity: 1;
		transform: translateX(0) translateY(0) translateZ(0);
	}
}

.search-modal {
	position: fixed;
	width: 100%;
	height: 0;
	overflow: hidden;
	top: 0;
	left: 0;
	z-index: 1080;
	background: #eee;
	justify-content: center;
	align-items: center;
	display: none;
	transition: all 250ms ease-in-out;
	&.shown {
		display: flex;
		animation: toFullHeight 300ms ease-in-out forwards;
	}
	input {
		background-color: #fff;
		font-size: 30px;
		font-weight: 700;
		padding: 30px 40px;
		border: none;
    border-radius: 30px;
	}
	button.ss-search-form__submit {
		font-size: 22px;
		margin: 20px;
        border: none;
	}
	.close-modal.close-modal-btn {
		font-size: 22px;
		font-weight: 700;
		display: inline-block;
		color: #333;
		margin: 10px;
	}
	.close-modal.close-modal-cross {
		position: absolute;
		width: 80px;
		height: 80px;
		display: block;
		border: 3px solid #757575;
		top: 30px;
		right: 30px;
		border-radius: 50%;
		&:before,
		&:after {
			content: "";
			position: absolute;
			width: 60%;
			height: 60%;
			top: 50%;
			left: 50%;
			height: 3px;
			display: block;
			background-color: #757575;
			transform: translate(-50%, -50%) rotate(45deg);
		}
		&:after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}
	.ss-search-form__results.ss-search-form__results--visible {
		padding: 30px 30px 10px;
		background: #fff;
		border-radius: 30px;
		.ss-autocomplete-result {
			font-size: 2.2rem;
			font-weight: 700;
			text-decoration: underline;
			a {
				color: $text;
			}
			.ss-autocomplete-result__badge {
				display: inline-block;
				background-color: $base-color;
				font-size: 1.2rem;
				color: #fff;
				padding: 4px 12px;
				border-radius: 6px;
				margin-left: 10px;
			}
		}
	}
}
@keyframes toFullHeight {
	0% {
		height: 0;
	}
	100% {
		height: 100%;
	}
}
@media (max-width: $bp-xs - 1) {
	.search-modal {
		input {
			display: table;
			width: 100%;
			max-width: 100%;
			font-size: 24px;
			padding: 20px 25px;
		}
		.close-modal.close-modal-cross {
			width: 50px;
			height: 50px;
		}
	}
}
@media (max-width: $bp-xxs - 1) {
	.search-modal {
		input {
			width: 100%;
			font-size: 18px;
		}
	}
}

@media (max-width: $nav-bp - 1) {
	#mainMenu .search-button {
		margin-left: auto;
	}
}
