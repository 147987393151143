@import "../../variables";
$header-background: #fff;

// Desktop (inherit) and mobile
header#mainMenu {
	background-color: $header-background;
	z-index: 990;
	height: $nav-height;
  @media (max-width: $nav-bp - 1) {
    height: 97px;
  }
}

// Mobile only
@media (max-width: $nav-bp - 1) {
	header#mainMenu {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background-color: $header-background;
		& > .container {
			position: relative;
		}
	}
	#mainMenu .header__wrapper {
		display: flex;
		align-items: center;
		nav {
			position: absolute;
			top: 50px;
			background-color: $header-background;
			width: 100%;
			left: 0;
			max-height: calc(100vh - 50px);
			padding-left: 10px;
			overflow: auto;
			padding-bottom: 0;
			box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .1);
			// UL settings
			ul {
				// list main
				width: 100%;
				height: auto;
				li {
					position: relative;
					width: 100%;
					border-bottom: 1px solid #cbcbcb;
					&:last-child {
						border-bottom: none;
					}
					a {
						display: block;
						padding: 16px 6px;
						font-weight: 700;
						font-size: 1.6rem;
						color: $text;
						position: relative;
						display: flex;
						align-items: center;
						text-transform: uppercase;
					}
					&.active > a,
					&.child-active > a {
						color: $base-color;
					}
					// Angle down styles
					&.w-submenu > a:after {
						content: "";
						width: 10px;
						display: block;
						height: 10px;
						border-bottom: 2px solid #333;
						border-right: 2px solid #333;
						transform: translateY(-5px) rotate(45deg);
						background-image: none;
						padding: 0;
						transition: transform 140ms ease-in;
						order: 3;
					}
					&.w-submenu > a::before {
						content: "";
						display: block;
						width: 16px;
						height: 2px;
						margin-left: 12px;
						background-color: #333;
						background-image: none;
						padding: 0;
						opacity: 0;
						transform: translate(-10px, 0);
						transition: all 140ms ease-in;
						order: 2;
					}
					&.w-submenu[data-status="clicked_once"] {
						& > a::after {
							transform: rotate(-45deg);
						}
						& > a::before {
							opacity: 1;
							transform: translate(10px);
						}
					}
				}
				li > ul {
					// First level
					padding-left: 15px;
					border-left: 4px solid $text;
					display: none;
					li a {
						font-size: 1.4rem;
					}
					& > li > ul {
						// Sec level
						padding-left: 15px;
						border-left: 4px solid $text;
						li ul {
							// Third level
							background: red;
						}
					}
				}
			}
		}
	}
	// Menu opening
	body {
		header#mainMenu nav {
			display: none;
		}
	}
	body.menuOpen {
		overflow: hidden;
		/*header#mainMenu {
			max-height: none;
			height: auto;
			overflow: auto;
		}*/
	}
}
