@import '../variables';
//Slick
.slick__arrow, .slick-banner__arrow {
	position: absolute;
	left: 0;
	top: 32%;
	width: 50px;
	height: 50px;
	background-image: url(../img/arrow-left.svg);
	background-color: #fff;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 65%  65%;
  padding: 10px;
  border-radius: 0 10px 10px 0;
	z-index: 10;
	border: none;
	outline: none;
	transition: all 250ms ease-in-out;
  transform: translate(0, -50%);
	cursor: pointer;
	//transform: translate(0, 50%);
	&.slick__arrow-next {
		background-image: url(../img/arrow-right.svg);
		left: unset;
		right: 0;
    border-radius: 10px 0 0 10px;
	}
  &.--banner{
    top: 45%;
  }
  @media screen and (max-width: $bp-xs - 1) {
    width: 30px;
	  height: 30px;
	}
}
.banner__slick-wrapper{
  .slick-banner__arrow {
    border-left: 1px solid #707070;
    left: 2px;
    &.slick__arrow-next {
      border-left: unset;
      border-right: 1px solid #707070;
      left: unset;
      right: 2px;
    }
  }
}
.slick-dots {
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	width: 100%;

	li {
		position: relative;
		display: inline-block;
		height: 20px;
		width: 20px;
		background-color: #f5f6f7;
		border-radius: 50%;
		margin-right: 5px;
		cursor: pointer;
    &:before {
      display: none;
    }
		&.slick-active {
			border: 3px solid $base-color;
			background-color: $base-color;
		}
	}
	li button {
		display: none;
	}
}
// .slick-track{
//   display: flex;
//   overflow: hidden;
// }
.banner-hp{
  display: flex;
  overflow: hidden;
}
