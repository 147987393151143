header#mainMenu.scrolled {
	background-color: #fff;
	box-shadow: 0px 6px 21px -9px rgba(0, 0, 0, 0.25);
	position: fixed;
	animation: slide-down 500ms;
}

@keyframes slide-down {
	0% {
		transform: translate(0, -100%);
	}

	100% {
		transform: translate(0, 0%);
	}
}
