@import '../variables';
.footer{
    background-color: $sec-color;
    padding-top: 100px;
    @media screen and (max-width: $bp-sm - 1) {
		padding-top: 40px;
	}
}
.footer__wrapper{
    display: flex;
    gap: 80px;
    padding-bottom: 80px;
    @media screen and (max-width: $bp-md - 1) {
      gap: 40px;
    }
    @media screen and (max-width: $bp-sm - 1) {
      flex-wrap: wrap;
      gap: 32px;
      padding-bottom: 40px;
	  }
}
.footer__col>*{
    color: #fff;
}
.footer__col{
    width: 25%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: $bp-sm - 1) {
		width: 100%;
	}
}
.footer__heading{
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 32px;
  &:before {
    position: absolute;
    content: "";
    width: 30%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: #fff;
  }
  @media screen and (max-width: $bp-sm - 1) {
    margin-bottom: 16px;
  }
}

.footer__contact-item{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
}
.footer__socials{
    display: flex;
    gap: 10px;
}
.footer__copyright{
    border-top: 1px solid #fff;
    padding: 20px 0;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;
    gap: 12px;
    div,a{
      color: #fff;
    }
}
.footer__menu{
  display: flex;
  flex-direction: column;
  gap: 16px;
  li{
    list-style-type: none;
    a{

      font-size: 1.8rem;
      color: #ffff
    }
  }
}

.pages-footer-menu{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

}
.pages-footer-menu__item{
  width: 100%;
}
.footer__menu-heading{
    margin-bottom: 34px;
}
