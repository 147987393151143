@import '../variables';
.newsletter__form{
  .newsletter__form-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
    input{
    border-radius: 10px;
    padding: 10px;
    width: 320px;
    max-width:100%;

  }
  .btn-std{
    margin: 0 0 0 -32px !important;
    border: unset;
    font-size: 1.6rem;
  }
}
.input-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert{

  background: $green;
  padding: 2rem;
  font-size: 22px;
  text-align: center;
  color: #fff;

}
.alert-danger{
  color: $red;
}
