@import "../../variables";

.header__icons{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3px 0;
  gap: 20px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.35);
  @media (max-width: $bp-sm - 1) {
    gap: 10px;
  }
}
.header__icon{
  display: table;
  transition: background-color 250ms ease-in-out;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  & > span {
    position: absolute;
    top: 100%;
    right: 0;
    opacity: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 7px 10px;
    z-index: 10;
    pointer-events: none;
    color: #000;
  }
  img{
    width: 24px;
    height: 24px;
  }
  &:hover{
    background-color: #f9f9f9;
    & > span {
      background-color: #f9f9f9;
      opacity: 1;
    }
  }
  @media (max-width: $bp-lg - 1) {
    img{
      width: 28px;
      height:28px;
    }
  }
  @media (max-width: $bp-xs - 1) {
    img{
      width: 24px;
      height:24px;
    }
  }
}
@media (min-width: $nav-bp) {
	#mainMenu {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		li a {
			color: $text;
			line-height: 1.4;
		}
		.header__wrapper {
			flex-grow: 1;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
		}
		nav {
			flex-grow: 1;
			display: flex;

			// UL settings
			ul {
				// Main list
				display: flex;
        @media (max-width: $bp-lg - 1) {
          margin-right: 0;
        }
				& > li {
					position: relative;
					&.w-submenu > a::after {
						content: "";
						width: .6rem;
						height: .6rem;
						border-bottom: 2px solid;
						border-right: 2px solid;
						margin-left: 8px;
						transform: rotate(45deg) translate(-3px);
					}
					a {
						display: flex;
						align-items: center;
						padding: 5px 10px;
						font-weight: 700;
						color: inherit;
						transition: none;
            margin-right: 8px;
						border: 2px solid transparent;
						transition: all 250ms ease-in-out;
						&:hover {
							color: $base-color;
						}
					}
					@media screen and (max-width: $bp-lg - 1) {
						a {
							font-size: 1.6rem;
							padding: 5px 0;
						}
					}
					@media screen and (max-width: $bp-md - 1) {
						a {
							font-size: 1.5rem;
							padding: 5px 0;
              margin-right: 4px;
						}
					}
					// &.w-submenu > a:after {
					// 	content: " ";
					// 	display: inline-block;
					// 	padding: 0 0 0 3px;
					// 	width: 1em;
					// 	height: 1em;

					// 	background-size: 12px auto;
					// 	vertical-align: middle;
					// 	transition: all 250ms ease-in-out;
					// }
				}
				& > li > ul {
					// First level
					//background-color: $base-color;
					position: absolute;
					top: 100%;
					left: 0;
					min-width: 280px;

					width: auto;
					flex-direction: column;
					opacity: 0;
					pointer-events: none;
					height: auto;
					max-height: calc(100vh - 170px);
					border-radius: 15px;
					background-color: $base-color;
					overflow: auto;
					&:last-child {
						left: unset;
						right: 0;
						&::before {
							left: unset;
							right: 60px;
						}
					}
					a {
						transition: padding-left 160ms ease-in;
					}
					a:hover {
						padding-left: 26px;
					}
					& > li {
						border-radius: 0;
						color: #fff;
						position: relative;
						padding: 5px 0;
						border-top: 1px solid rgba(255, 255, 255, .35);
						a {
							display: block;
							border-radius: 0;
							color: #fff;
							padding-left: 14px;
							padding-right: 14px;
              &:hover {
                color: #fff;
              }
						}
						&:hover > a {
							color: #fff;
						}
						&.w-submenu > a:after {
							content: "";
							position: absolute;
							right: 10px;
							left: auto;
							top: 1.2em;
							width: 10px;
							height: 10px;
							border-bottom: 3px solid #fff;
							border-right: 3px solid #ffffff;
							transform: translate(0, -50%) rotate(45deg);
							background-image: none;
							padding: 0;
							transition: transform 140ms ease-in;
						}
						&.w-submenu > a::before {
							content: "";
							position: absolute;
							right: 18px;
							left: auto;
							top: 1.2em;
							width: 16px;
							height: 2px;
							background-color: #fff;
							background-image: none;
							padding: 0;
							opacity: 0;
							transform: translate(-10px, 0);
							transition: all 140ms ease-in;
						}
						&.w-submenu:hover > a:after {
							transform: rotate(45deg);
						}
						&.w-submenu[data-status="clicked_once"] {
							& > a::after {
								transform: translate(-10px, -50%) rotate(-45deg);
							}
							& > a::before {
								opacity: 1;
								transform: translate(-10px);
							}

							a {
								background-color: $base-color;
								// &:hover {
								// 	background-color: $base-color;
								// }
								&:hover:after {
									transform: translate(0, -50%) rotate(-45deg);
								}
								&:hover:before {
									transform: translate(0);
								}
							}
						}
					}
					li > ul {
						// Sec level
						position: relative;
						display: none;
						opacity: 1;
						background-color: transparent;
						border-left: 9px solid $sec-color;
						& > li {
							width: 100%;
							color: #fff;
							a {
								position: relative;
								z-index: 10000;
								white-space: normal;
								color: inherit;
								padding-left: 20px;
								&:hover {
									background-color: $sec-color;
								}
							}
						}
						li ul {
							// Third level
							display: none;
							border-left: 12px solid #fff;
						}
					}
				}
				// Trigger for main menu
				li:hover,
				//li:focus-within,
				li:focus {
					& > ul {
						opacity: 1;
						pointer-events: all;
					}
				}
			}
		}
	}
}

#mainMenu {
	.header__logo {
		width: 280px;
		max-width: 100%;
		height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    a{
      display: table;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media (max-width: $bp-lg - 1) {
      width: 200px;
      height:auto;
    }
    @media (max-width: $bp-md - 1) {
      width: 180px;
      height:auto;
    }
	}
}
