@import "../_variables.scss";

.mainContainer > section {
	padding: 70px 0;
	@media (max-width: $bp-xs - 1) {
		padding: 35px 0;
	}
}

//Small-container
.--small-container {
	@media (min-width: $bp-md - 1) {
		max-width: 950px !important;
	}
}
.body-page-detail, .body-gallery-detail{
  background-color: $yellow;
}
.heading-intro {
	color: $base-color;
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 8px;
	display: block;
  &.--center{
    text-align: center;
  }
}
